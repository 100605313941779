import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PadBox from "@bedrock-layout/padbox"
import Stack from "@bedrock-layout/stack"
import styled from "styled-components"
import Obfuscate from "react-obfuscate"
import hex from "../images/Arik-hex.png"

const Title = styled.h1`
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 4px rgba(20, 20, 20, 0.4);
`

const FullPageBackground = styled(PadBox)`
  min-height: 100vh;
  background: #255b77;
  text-align: center;
`
const StyledObfuscate = styled(Obfuscate)`
  text-decoration: none;
  color: #ed7723;
  font-weight: bolder;
`
const Anchor = styled.a`
  text-decoration: none;
  color: #ed7723;
  font-weight: bolder;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img {
    width: ${props => props.width};
    max-width: 100%;
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
  }
`
const HomeImage = ({ src, alt, width = 350 }) => (
  <img src={src} alt={alt} width={width} style={{ margin: 0 }} />
)

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <FullPageBackground justify="center" padding={["xl"]}>
      <Title>Contact Me</Title>
      <PadBox style={{ color: "#fff" }}>
        <Stack gutter="xl">
          <div>
            Please feel free to{" "}
            <StyledObfuscate
              email="arik.parker@gmail.com"
              headers={{
                subject: "Hi Arik!",
              }}
            >
              drop me a line!
            </StyledObfuscate>
          </div>
          <div>
            I'm also happy to{" "}
            <span>
              <Anchor
                href="https://www.linkedin.com/in/arik-parker-39ab8b31/"
                target="_blank"
                rel="noopener noreferrer"
              >
                connect on LinkedIn!
              </Anchor>
            </span>
          </div>
        </Stack>
      </PadBox>
      <PadBox data-aos="zoom-in" data-aos-delay="300" data-aos-duration="1200">
        <ImageWrapper className="shrink">
          <HomeImage src={hex} alt="Arik in a hexagon" width="350px" />
        </ImageWrapper>
      </PadBox>
    </FullPageBackground>
  </Layout>
)

export default Contact
